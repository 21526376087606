import React from "react";
import styled from "styled-components/macro";

import { Alert as MuiAlert } from "@mui/material";
import { spacing } from "@mui/system";

// import Default from "../dashboards/Default/IndexShipperHome";

const Alert = styled(MuiAlert)(spacing);

function ProtectedPage() {
  return (
    <>
      <Alert mb={4} severity="info">
        This page is only visible by authenticated users.
      </Alert>

      {/* <Default /> */}
    </>
  );
}

export default ProtectedPage;
