import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import userReducer from "./slices/user";
import SellerOrdersReducer from "./slices/SellerOrders";
import SellerOrderUrlImagesReducer from "./slices/SellerOrderUrlImages";
import SellerOrderUrlsReducer from "./slices/SellerOrderUrls";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    SellerOrders: SellerOrdersReducer,
    SellerOrderUrlImages: SellerOrderUrlImagesReducer,
    SellerOrderUrls: SellerOrderUrlsReducer,
  },
});
