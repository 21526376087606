import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Select,
  MenuItem,
  FormControl as MuiFormControl,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import { authUserType } from "../../utils/defaultStatus";
import { updateUserMobileNumber } from "../../redux/slices/user";
import {
  getCurrentDateTime,
  validateMobile,
} from "../../utils/commonFunctions";
import { authConstants } from "../../utils/authConstants";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 30%;
`;

function MobileNumber({ setComponent, userTypeProp }) {
  const [logingErrors, setLoggingErrors] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [validMobileNumber, setMobileNumber] = useState();
  const { MOBILE_OTP } = authConstants;

  const [commit, isInFlight] = useMutation(graphql`
    mutation MobileNumberMutation(
      $id: ID!
      $user: UserUpdateInput!
      $updated_date: String
    ) {
      addUserMobileNumber(id: $id, user: $user, updated_date: $updated_date) {
        _id
        user_name
        seller {
          _id
          company_name
          full_name
          email
          phone1
        }
        token
      }
    }
  `);

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Let's add your mobile phone number
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please enter your mobile phone number below. We will use this mobile
        phone number as primary point of contact and in two factor
        authentication to secure your account.
      </Typography>
      <Formik
        initialValues={{
          mobileNumber: null,
          countryCode: "+94",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          mobileNumber: Yup.number()
            .typeError("Please enter a valid mobile phone number")
            .test(
              "maxDigits",
              "Please enter a valid mobile phone number",
              (number) => String(number).length === 9
            )
            .positive()
            .required("Mobile number is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const { SELLER } = authUserType;
          const { countryCode } = values;
          try {
            if (userTypeProp === SELLER) {
              commit({
                variables: {
                  id: user._id,
                  user: {
                    user_type: SELLER,
                    seller: {
                      country_code: countryCode,
                      phone1: validMobileNumber,
                    },
                  },
                  updated_date: getCurrentDateTime(),
                },
                onCompleted(data) {
                  const { addUserMobileNumber } = data;
                  if (addUserMobileNumber) {
                    const { seller } = addUserMobileNumber || {};
                    const { phone1 } = seller || {};
                    dispatch(
                      updateUserMobileNumber({
                        mobileNumber: phone1 || "",
                      })
                    );
                    setComponent(MOBILE_OTP);
                  }
                },
              });
            }
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
            setLoggingErrors(error.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {logingErrors && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={setLoggingErrors("")}
              >
                {logingErrors}
              </Alert>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl>
                <Select
                  value={values.countryCode}
                  displayEmpty
                  name="countryCode"
                  inputProps={{ "aria-label": "Without label" }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <MenuItem value="+94" selected="selected">
                    LK (+94)
                  </MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: "70%", paddingLeft: "10px" }}>
                <TextField
                  type="text"
                  name="mobileNumber"
                  label="Mobile Phone Number"
                  fullWidth
                  value={values.mobileNumber}
                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  helperText={touched.mobileNumber && errors.mobileNumber}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const { value } = e.target || "";
                    handleChange(e);
                    setMobileNumber(validateMobile(value || ""));
                  }}
                  my={3}
                  style={{ display: "inline-block" }}
                />
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="37.5"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default MobileNumber;
