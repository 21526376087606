import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useSelector } from "react-redux";
import { useMutation, fetchQuery, useRelayEnvironment } from "react-relay";
import { authConstants } from "../../utils/authConstants";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

function EmailOtp({ setComponent }) {
  const environment = useRelayEnvironment();
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [apiErrors, setApiErrors] = useState("");
  const [newOtpStatus, setNewOtpStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [commit, isInFlight] = useMutation(graphql`
    mutation EmailOtpMutation($user_name: String, $otp_type: String) {
      resendOtp(user_name: $user_name, otp_type: $otp_type) {
        otp_value
        otp_type
        user {
          user_name
        }
      }
    }
  `);

  const fetchOtp = (otp, username) => {
    fetchQuery(
      environment,
      graphql`
        query EmailOtpQuery(
          $user_name: String
          $otp_value: Int
          $otp_type: String
        ) {
          verifyUserAccount(
            user_name: $user_name
            otp_value: $otp_value
            otp_type: $otp_type
          ) {
            otp_value
            otp_type
          }
        }
      `,
      {
        user_name: username,
        otp_value: otp,
        otp_type: "email",
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: (e) => {
        setApiErrors(e.message);
        setIsLoading(false);
      },
      next: (data) => {
        if (data.verifyUserAccount) {
          setComponent(authConstants.MOBILE_NUMBER);
        }
      },
    });
  };
  const otpClick = (emailOtp) => {
    const otpInt = parseInt(emailOtp);

    fetchOtp(otpInt, user.email);
  };

  const resendEmailOtp = (resetForm) => {
    commit({
      variables: {
        user_name: user.email,
        otp_type: "email",
      },
      onCompleted(data, error) {
        if (data.resendOtp) {
          setMinutes(2);
          setSeconds(0);
          resetForm();
          setApiErrors("");
          setNewOtpStatus("success");
        }
        if (error) {
          setNewOtpStatus("fail");
        }
      },
    });
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Verify Email Address
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        We have sent a verification email to your email address. Please enter
        the OTP provided in the email below to continue.
      </Typography>
      <Formik
        initialValues={{
          emailOtp: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          emailOtp: Yup.string().max(255).required("Email OTP is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            otpClick(values.emailOtp);
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {apiErrors !== "" && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={() => setApiErrors("")}
              >
                {apiErrors}
              </Alert>
            )}
            {newOtpStatus === "fail" && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={() => setNewOtpStatus("")}
              >
                New OTP sending failed. Please try again.
              </Alert>
            )}
            {newOtpStatus === "success" && (
              <Alert
                mb={4}
                variant="filled"
                severity="success"
                onClose={() => setNewOtpStatus("")}
              >
                New OTP sent successfully.
              </Alert>
            )}
            <TextField
              type="text"
              name="emailOtp"
              label="Enter OTP"
              value={values.emailOtp}
              error={Boolean(touched.emailOtp && errors.emailOtp)}
              fullWidth
              helperText={touched.emailOtp && errors.emailOtp}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                mr={2}
                style={{ marginBottom: "10px", padding: "0" }}
                disabled={!(minutes === 0 && seconds === 0)}
                onClick={() => resendEmailOtp(resetForm)}
              >
                Re-send verification email
              </Button>
              {minutes === 0 && seconds === 0 ? (
                <Typography
                  variant="h6"
                  align="end"
                  style={{
                    display: "inline-block",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  0:00 min
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  align="end"
                  style={{
                    display: "inline-block",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds} min
                </Typography>
              )}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight || isLoading}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="25"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default EmailOtp;
