import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import HideImageIcon from "@mui/icons-material/HideImage";

function ImageNotAvailable() {
  return (
    <Grid container spacing={2}>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        justifyContent="center"
      >
        <Box
          sx={{
            width: 300,
            height: 300,
            border: "dashed grey",
          }}
        >
          <HideImageIcon sx={{ fontSize: 60, ml: 30, mt: 30 }} />
          <Typography align="Center">Images are not available</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
export default ImageNotAvailable;
