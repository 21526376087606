/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignInSellerMutationVariables = {|
  username: string,
  password: string,
|};
export type SignInSellerMutationResponse = {|
  +loginUser: ?{|
    +user_id: ?string,
    +token: ?string,
    +user_type: ?string,
    +user_activation_status: ?string,
    +user_status: ?boolean,
    +user_email_verify_status: ?boolean,
    +user_mobile_verify_status: ?boolean,
    +user: ?{|
      +company_name: ?string,
      +_id: ?string,
      +full_name: ?string,
      +email: ?string,
      +phone1: ?string,
    |},
    +user_setup_status: ?boolean,
    +is_config: ?boolean,
  |}
|};
export type SignInSellerMutation = {|
  variables: SignInSellerMutationVariables,
  response: SignInSellerMutationResponse,
|};
*/


/*
mutation SignInSellerMutation(
  $username: String!
  $password: String!
) {
  loginUser(username: $username, password: $password) {
    user_id
    token
    user_type
    user_activation_status
    user_status
    user_email_verify_status
    user_mobile_verify_status
    user {
      company_name
      _id
      full_name
      email
      phone1
    }
    user_setup_status
    is_config
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "LoginResultType",
    "kind": "LinkedField",
    "name": "loginUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_activation_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_email_verify_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_mobile_verify_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Seller",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "company_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone1",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_setup_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_config",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInSellerMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SignInSellerMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4ca0921aa16cc41af758a5d6570df08f",
    "id": null,
    "metadata": {},
    "name": "SignInSellerMutation",
    "operationKind": "mutation",
    "text": "mutation SignInSellerMutation(\n  $username: String!\n  $password: String!\n) {\n  loginUser(username: $username, password: $password) {\n    user_id\n    token\n    user_type\n    user_activation_status\n    user_status\n    user_email_verify_status\n    user_mobile_verify_status\n    user {\n      company_name\n      _id\n      full_name\n      email\n      phone1\n    }\n    user_setup_status\n    is_config\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc492dccebe84aff695ed6ffa76f2825';

module.exports = node;
