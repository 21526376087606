import momentTimezone from "moment-timezone";
import moment from "moment";

// convert snake case to title case
export const snakeCaseToTitleText = (string) => {
  return string
    .split("_")
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

// convert timestamp to human readable date
export const getDate = (timestamp) => {
  const date = momentTimezone(timestamp / 1000, "X")
    .tz("Asia/Colombo")
    .subtract(5.5, "hours")
    .format("DD-MM-YYYY hh:mm a");
  return date;
};
// convert timestamp to year:month:time format
export const getDateWithoutTime = (timestamp) => {
  const date = momentTimezone(timestamp / 1000, "X")
    .tz("Asia/Colombo")
    .subtract(5.5, "hours")
    .format("DD-MM-YYYY");
  return date;
};

// format long numbers by comma
export const formatAmount = (amount) => {
  return amount?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const getCurrentDateTime = () => {
  const currentTimeZone = momentTimezone();
  return currentTimeZone.tz("Asia/Colombo").format("YYYY-MM-DD HH:mm:ss");
};
export const filterDate = (date) => {
  return (
    moment(date)
      .tz("Asia/Colombo")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss") || null
  );
};
export const validateMobile = (mobileNumber) => {
  if (mobileNumber) {
    const number = Number(mobileNumber);
    return String(number);
  }
  return "";
};
