import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import useAuth from "../../hooks/useAuth";
import { authConstants } from "../../utils/authConstants";
import { authUserType, userActivationStatus } from "../../utils/defaultStatus";
import { setUser } from "../../redux/slices/user";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn({ setAuthComponent }) {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const dispatch = useDispatch();
  const [loginUserDetails, setLoginUser] = useState({});
  const [invalidUserAlert, setInvalidUserAlert] = useState({
    status: false,
    alertMessage: "",
  });
  const [isEmailValidAccount, setIsEmailValidAccount] = useState(false);
  const [isMobileValidAccount, setIsMobileValidAccount] = useState(false);
  const { SELLER } = authUserType;
  const { DEACTIVATE } = userActivationStatus;
  const { SIGN_UP } = authConstants;
  const { status, alertMessage } = invalidUserAlert;

  const [commit, isInFlight] = useMutation(graphql`
    mutation SignInMutation($user_name: String, $otp_type: String) {
      resendOtp(user_name: $user_name, otp_type: $otp_type) {
        otp_value
        otp_type
        user {
          user_name
        }
      }
    }
  `);
  const [commitSignInSeller] = useMutation(graphql`
    mutation SignInSellerMutation($username: String!, $password: String!) {
      loginUser(username: $username, password: $password) {
        user_id
        token
        user_type
        user_activation_status
        user_status
        user_email_verify_status
        user_mobile_verify_status
        user {
          company_name
          _id
          full_name
          email
          phone1
        }
        user_setup_status
        is_config
      }
    }
  `);

  const resendEmailOtp = () => {
    const { EMAIL_OTP } = authConstants;
    const {
      user_type,
      user: { email, company_name },
    } = loginUserDetails;

    switch (user_type) {
      case SELLER:
        commit({
          variables: {
            user_name: email || "",
            otp_type: "email",
          },
          onError(error) {
            setInvalidUserAlert({
              status: true,
              alertMessage: error.message,
            });
          },
          onCompleted(data, error) {
            if (data.resendOtp) {
              navigate(`/${company_name || ""}/auth/sign_up`, {
                state: { component: EMAIL_OTP },
                replace: true,
              });
              setAuthComponent(SIGN_UP);
            }
            if (error) {
              setInvalidUserAlert({
                status: true,
                alertMessage: error.message,
              });
            }
          },
        });
        break;
      default:
        break;
    }
  };
  const resendMobileOtpMessege = () => {
    const { MOBILE_NUMBER } = authConstants;
    const {
      user_type,
      user: { company_name },
    } = loginUserDetails;
    switch (user_type) {
      case authUserType.SELLER:
        navigate(`/${company_name || ""}/auth/sign_up`, {
          state: { component: MOBILE_NUMBER },
          replace: true,
        });
        setAuthComponent(SIGN_UP);
        break;

      default:
        break;
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const { email, password } = values;
        try {
          commitSignInSeller({
            variables: {
              username: email || "",
              password: password || "",
            },
            onError(error) {
              setInvalidUserAlert({
                status: true,
                alertMessage: error.message,
              });
            },
            onCompleted(data) {
              const response = data.loginUser;
              const {
                user_type,
                user_activation_status,
                user_email_verify_status,
                user_mobile_verify_status,
                user: { company_name },
              } = response;

              if (user_type === SELLER) {
                if (!user_email_verify_status) {
                  dispatch(setUser(response));
                  setLoginUser(response);
                  setIsEmailValidAccount(true);
                  setErrors({
                    submit: "Something went wrong",
                    loginFailed:
                      "This account is not verified. Please resend verification email and verify your account.",
                  });
                } else if (!user_mobile_verify_status) {
                  dispatch(setUser(response));
                  setLoginUser(response);
                  setIsMobileValidAccount(true);
                  setErrors({
                    submit: "Something went wrong",
                    loginFailed:
                      "This account is not verified. Please resend verification mobile number and verify your account.",
                  });
                } else if (user_activation_status === DEACTIVATE) {
                  setErrors({
                    submit: "Something went wrong",
                    loginFailed:
                      "This account has been suspended. Please contact admin for more information.",
                  });
                } else {
                  signIn(response);
                  dispatch(setUser(response));
                  navigate(`/seller/${company_name}/orders/PendingOrders`);
                  window.location.reload();
                }
              }
            },
          });
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({
            submit: message,
            loginFailed: "Invalid Username or Password!",
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        isSubmitting,
        values,
        setErrors,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert
              mt={2}
              mb={3}
              severity="warning"
              onClose={() => setErrors({})}
            >
              {errors.loginFailed}
            </Alert>
          )}
          {status && (
            <Alert
              mt={2}
              mb={3}
              severity="warning"
              onClose={() =>
                setInvalidUserAlert({ status: false, alertMessage: "" })
              }
            >
              {alertMessage}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h8">Don't have an account?</Typography>
            <Button color="primary" onClick={() => setAuthComponent(SIGN_UP)}>
              Sign up
            </Button>
          </div>
          {!isMobileValidAccount && !isEmailValidAccount && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
          )}

          {isEmailValidAccount && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
              onClick={() => resendEmailOtp()}
            >
              Re-send verification email
            </Button>
          )}

          {!isEmailValidAccount && isMobileValidAccount && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
              onClick={() => resendMobileOtpMessege()}
            >
              Re-send verification mobile number
            </Button>
          )}
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
