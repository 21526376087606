/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderInputType = {|
  name?: ?string,
  quantity?: ?number,
  address?: ?string,
  mobile_number1?: ?string,
  mobile_number2?: ?string,
  order_url?: ?ShipmentInputType,
  status?: ?string,
  cod_value?: ?number,
  created_time?: ?string,
|};
export type ShipmentInputType = {|
  _id?: ?string,
  product_token?: ?string,
  product_code?: ?string,
  product_url?: ?string,
  product_name?: ?string,
  color?: ?string,
  size?: ?string,
  product_description?: ?string,
  product_image?: ?$ReadOnlyArray<?string>,
  weight?: ?number,
  weight_type?: ?string,
  product_price?: ?number,
  delivery_fee?: ?number,
  url_status?: ?boolean,
  seller?: ?OrderSellerInput,
|};
export type OrderSellerInput = {|
  _id?: ?string,
  seller_id?: ?string,
  full_name?: ?string,
  company_name?: ?string,
  phone1?: ?string,
  address_line1?: ?string,
  email?: ?string,
|};
export type UserPlaceOrderFormMutationVariables = {|
  customer_order?: ?OrderInputType
|};
export type UserPlaceOrderFormMutationResponse = {|
  +addSellerOrder: ?{|
    +_id: ?string,
    +quantity: ?number,
    +name: ?string,
    +address: ?string,
    +mobile_number1: ?string,
    +mobile_number2: ?string,
  |}
|};
export type UserPlaceOrderFormMutation = {|
  variables: UserPlaceOrderFormMutationVariables,
  response: UserPlaceOrderFormMutationResponse,
|};
*/


/*
mutation UserPlaceOrderFormMutation(
  $customer_order: OrderInputType
) {
  addSellerOrder(customer_order: $customer_order) {
    _id
    quantity
    name
    address
    mobile_number1
    mobile_number2
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customer_order"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "customer_order",
        "variableName": "customer_order"
      }
    ],
    "concreteType": "Order",
    "kind": "LinkedField",
    "name": "addSellerOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobile_number1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobile_number2",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPlaceOrderFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPlaceOrderFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5213832c0e86f0b6d0b1f2de84f1f460",
    "id": null,
    "metadata": {},
    "name": "UserPlaceOrderFormMutation",
    "operationKind": "mutation",
    "text": "mutation UserPlaceOrderFormMutation(\n  $customer_order: OrderInputType\n) {\n  addSellerOrder(customer_order: $customer_order) {\n    _id\n    quantity\n    name\n    address\n    mobile_number1\n    mobile_number2\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d086686e4c8079234b574316b2c8381';

module.exports = node;
