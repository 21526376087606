import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { commonConstants } from "../../utils/commonConstants";

const initialState = {
  user: {},
  serviceProvider: {},
  token: "",
  status: false,
  isChangePasswordLoading: false,
  changePasswordStatus: "",
  changePasswordResponseMessage: "",
  isResetPasswordEmailLoading: false,
  resetPasswordEmailResponseMessage: "",
  resetPasswordEmailStatus: "",
  isResetPasswordLoading: false,
  resetPasswordResponseMessage: "",
  resetPasswordStatus: "",
};

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ oldPassword, newPassword }, { getState }) => {
    try {
      let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/update-password`;
      if (process.env.REACT_APP_API_URL) {
        url = `${process.env.REACT_APP_API_URL}/update-password`;
      }
      const response = await axios.post(url, {
        user_id: getState().user.user.user_id,
        old_password: oldPassword,
        new_password: newPassword,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const resetPasswordEmail = createAsyncThunk(
  "user/resetPasswordEmail",
  async ({ email }) => {
    try {
      let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/reset-password-email`;
      if (process.env.REACT_APP_API_URL) {
        url = `${process.env.REACT_APP_API_URL}/reset-password-email`;
      }
      const response = await axios.post(url, {
        user_name: email,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "user/resetUserPassword",
  async ({ userId, newPassword }) => {
    try {
      let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/reset-password`;
      if (process.env.REACT_APP_API_URL) {
        url = `${process.env.REACT_APP_API_URL}/reset-password`;
      }
      const response = await axios.post(url, {
        user_id: userId,
        new_password: newPassword,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { token, user } = action.payload;
      state.user = user;
      state.token = token;
    },
    updateUserMobileNumber: (state, action) => {
      const { mobileNumber } = action.payload;
      state.user = {
        ...state.user,
        seller_mobile_number: mobileNumber,
      };
    },
    resetUser: (state) => {
      const user = localStorage.getItem("user");
      const jUser = JSON.parse(user);
      // const token = localStorage.getItem("accessToken");
      state.user = jUser;
      // state.token = token.toString();
    },
    removeUser: (state) => {
      state.user = {};
      state.token = "";
    },
    resetChangePasswordState: (state) => {
      state.changePasswordStatus = "";
    },
    setServiceProvider: (state, { payload }) => {
      state.serviceProvider = payload;
    },
  },
  extraReducers: (builder) => {
    const { SUCCESS, FAILED } = commonConstants;
    builder.addCase(changePassword.pending, (state) => {
      state.isChangePasswordLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      if (action.payload.data.message === "Password changed successfully") {
        state.changePasswordStatus = SUCCESS;
        state.isChangePasswordLoading = false;
        state.changePasswordResponseMessage = action.payload.data.message;
      } else {
        state.changePasswordStatus = FAILED;
        state.isChangePasswordLoading = false;
        state.changePasswordResponseMessage = action.payload.data.message;
      }
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.changePasswordStatus = FAILED;
      state.isChangePasswordLoading = false;
      state.changePasswordResponseMessage = "";
    });
    builder.addCase(resetPasswordEmail.pending, (state) => {
      state.isResetPasswordEmailLoading = true;
    });
    builder.addCase(resetPasswordEmail.fulfilled, (state, action) => {
      const {
        payload: {
          data: { message },
        },
      } = action;
      if (message === "Email sent successfully") {
        state.resetPasswordEmailStatus = SUCCESS;
        state.isResetPasswordEmailLoading = false;
        state.resetPasswordEmailResponseMessage = message;
      } else {
        state.resetPasswordEmailStatus = FAILED;
        state.isResetPasswordEmailLoading = false;
        state.resetPasswordEmailResponseMessage = message;
      }
    });
    builder.addCase(resetPasswordEmail.rejected, (state) => {
      state.resetPasswordEmailStatus = FAILED;
      state.isResetPasswordEmailLoading = false;
      state.resetPasswordEmailResponseMessage = "";
    });
    builder.addCase(resetUserPassword.pending, (state) => {
      state.isResetPasswordLoading = true;
    });
    builder.addCase(resetUserPassword.fulfilled, (state, action) => {
      const {
        payload: {
          data: { message },
        },
      } = action;
      if (message === "Password reset successfully") {
        state.resetPasswordStatus = SUCCESS;
        state.isResetPasswordLoading = false;
        state.resetPasswordResponseMessage = message;
      } else {
        state.resetPasswordStatus = FAILED;
        state.isResetPasswordLoading = false;
        state.resetPasswordResponseMessage = message;
      }
    });
    builder.addCase(resetUserPassword.rejected, (state) => {
      state.resetPasswordStatus = FAILED;
      state.isResetPasswordLoading = false;
      state.resetPasswordResponseMessage = "";
    });
  },
});

export const {
  setUser,
  resetUser,
  removeUser,
  resetChangePasswordState,
  setServiceProvider,
  updateUserMobileNumber,
} = userSlice.actions;

export default userSlice.reducer;
