import * as React from "react";
import { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon, Settings, User, Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import "../../styles/common.css";
import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
  Button,
  Alert as MuiAlert,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { useNavigate } from "react-router-dom";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { resetUser } from "../../redux/slices/user";
import { authUserType } from "../../utils/defaultStatus";
import { employeeTypeConstants } from "../../utils/employeeTypeConstants";
import Loader from "../MiniLoader";

const Alert = styled(MuiAlert)(spacing);

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

function Navbar({ onDrawerToggle }) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const environment = useRelayEnvironment();
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openProgressModal, setOpenProgressModal] = React.useState(false);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.user);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      fetchShipmentDetails();
    }
  };

  const getSPDisplayName = () => {
    const user = localStorage.getItem("user");
    const userJson = JSON.parse(user);
    if (userJson) {
      return {
        userDisplayName: userJson.shipper_company_name,
        userSPDisplayName:
          userJson.service_provider.service_provider_company_name,
      };
    }
    return "";
  };

  const getUserType = () => {
    const user = localStorage.getItem("user");
    const userJson = JSON.parse(user);
    if (userJson) {
      return userJson.user_type;
    }
    return "";
  };

  const getEmployeeType = () => {
    const user = localStorage.getItem("user");
    const userJson = JSON.parse(user);
    if (userJson) {
      return userJson.employee_type;
    }
    return "";
  };

  useEffect(() => {
    dispatch(resetUser());
  }, []);
  return (
    <AppBar position="sticky" elevation={0} className="responsiveContainer">
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          {/* temporary hidden
             <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input
                  placeholder={t("Search")}
                  onChange={handleChange}
                  onKeyDown={handleSearch}
                />
              </Search>
            </Grid> */}
          {isLoading && <Loader size={25} />}
          <Grid item xs />
          <Grid item>
            {getUserType() === authUserType.EMPLOYEE && (
              <Tooltip title="Profile">
                <IconButton
                  color="inherit"
                  onClick={() =>
                    navigate(
                      `/${
                        getSPDisplayName().userSPDisplayName
                      }/account_settings`
                    )
                  }
                  size="large"
                >
                  <User />
                </IconButton>
              </Tooltip>
            )}

            <NavbarUserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default withTheme(Navbar);
