/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserUpdateInput = {|
  user_name?: ?string,
  password?: ?string,
  user_type: string,
  user_level?: ?string,
  seller?: ?SellerInput,
  user_status?: ?boolean,
  user_create_by?: ?UserUpdateInputField,
  otp_type?: ?string,
|};
export type SellerInput = {|
  _id?: ?string,
  seller_id?: ?string,
  full_name?: ?string,
  other_name?: ?string,
  company_name?: ?string,
  display_name?: ?string,
  country_code?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  email?: ?string,
|};
export type UserUpdateInputField = {|
  _id?: ?string,
  user_name?: ?string,
  user_type?: ?string,
|};
export type MobileNumberMutationVariables = {|
  id: string,
  user: UserUpdateInput,
  updated_date?: ?string,
|};
export type MobileNumberMutationResponse = {|
  +addUserMobileNumber: ?{|
    +_id: ?string,
    +user_name: ?string,
    +seller: ?{|
      +_id: ?string,
      +company_name: ?string,
      +full_name: ?string,
      +email: ?string,
      +phone1: ?string,
    |},
    +token: ?string,
  |}
|};
export type MobileNumberMutation = {|
  variables: MobileNumberMutationVariables,
  response: MobileNumberMutationResponse,
|};
*/


/*
mutation MobileNumberMutation(
  $id: ID!
  $user: UserUpdateInput!
  $updated_date: String
) {
  addUserMobileNumber(id: $id, user: $user, updated_date: $updated_date) {
    _id
    user_name
    seller {
      _id
      company_name
      full_name
      email
      phone1
    }
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updated_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "updated_date",
        "variableName": "updated_date"
      },
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "addUserMobileNumber",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SellerReturn",
        "kind": "LinkedField",
        "name": "seller",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "company_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone1",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileNumberMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MobileNumberMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1d205f9a98d16fc02ddd359aa412d057",
    "id": null,
    "metadata": {},
    "name": "MobileNumberMutation",
    "operationKind": "mutation",
    "text": "mutation MobileNumberMutation(\n  $id: ID!\n  $user: UserUpdateInput!\n  $updated_date: String\n) {\n  addUserMobileNumber(id: $id, user: $user, updated_date: $updated_date) {\n    _id\n    user_name\n    seller {\n      _id\n      company_name\n      full_name\n      email\n      phone1\n    }\n    token\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76935b0940eeac486174d073ddbb752b';

module.exports = node;
