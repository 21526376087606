import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import SignUpComponent from "../../components/auth/SellerSignUp";
import EmailOtpComponent from "../../components/auth/EmailOtp";
import MobileNumberComponent from "../../components/auth/MobileNumber";
import MobileOtpComponent from "../../components/auth/MobileOtp";

import { authConstants } from "../../utils/authConstants";
import { authUserType } from "../../utils/defaultStatus";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const renderSignUp = ({
  componentName,
  setComponentName,
  setAuthComponent,
}) => {
  const { EMAIL_OTP, MOBILE_NUMBER, MOBILE_OTP } = authConstants;
  const { SELLER } = authUserType;
  const location = useLocation();
  const currentComponentName = componentName || location.state?.component;
  switch (currentComponentName) {
    case EMAIL_OTP:
      return (
        <EmailOtpComponent
          setComponent={setComponentName}
          userTypeProp={SELLER}
        />
      );
    case MOBILE_NUMBER:
      return (
        <MobileNumberComponent
          setComponent={setComponentName}
          userTypeProp={SELLER}
        />
      );
    case MOBILE_OTP:
      return (
        <MobileOtpComponent
          setComponent={setComponentName}
          userTypeProp={SELLER}
        />
      );

    default:
      return (
        <SignUpComponent
          setComponent={setComponentName}
          setAuthComponent={setAuthComponent}
          userTypeProp={authUserType.SELLER}
        />
      );
  }
};

function SellerSignUp({ setAuthComponent }) {
  const [componentName, setComponentName] = useState("");

  return (
    <>
      <Helmet title="Sign Up" />
      <Wrapper>
        {renderSignUp({ componentName, setComponentName, setAuthComponent })}
      </Wrapper>
    </>
  );
}

export default SellerSignUp;
