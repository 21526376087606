/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserInput = {|
  user_name: string,
  password: string,
  user_type: string,
  user_level: string,
  seller?: ?SellerInput,
  user_status?: ?boolean,
  user_email_verify_status?: ?boolean,
  user_mobile_verify_status?: ?boolean,
  user_create_by?: ?UserInputField,
  otp_type: string,
|};
export type SellerInput = {|
  _id?: ?string,
  seller_id?: ?string,
  full_name?: ?string,
  other_name?: ?string,
  company_name?: ?string,
  display_name?: ?string,
  country_code?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  email?: ?string,
|};
export type UserInputField = {|
  _id?: ?string,
  user_name?: ?string,
  user_type?: ?string,
|};
export type SellerSignUpMutationVariables = {|
  user: UserInput
|};
export type SellerSignUpMutationResponse = {|
  +addSeller: ?{|
    +_id: ?string,
    +email: ?string,
    +full_name: ?string,
    +seller_id: ?string,
    +company_name: ?string,
    +token: ?string,
  |}
|};
export type SellerSignUpMutation = {|
  variables: SellerSignUpMutationVariables,
  response: SellerSignUpMutationResponse,
|};
*/


/*
mutation SellerSignUpMutation(
  $user: UserInput!
) {
  addSeller(user: $user) {
    _id
    email
    full_name
    seller_id
    company_name
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user"
      }
    ],
    "concreteType": "Seller",
    "kind": "LinkedField",
    "name": "addSeller",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "full_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "seller_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "company_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SellerSignUpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SellerSignUpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8c86b58b0ae6b595001ad177cce6bcea",
    "id": null,
    "metadata": {},
    "name": "SellerSignUpMutation",
    "operationKind": "mutation",
    "text": "mutation SellerSignUpMutation(\n  $user: UserInput!\n) {\n  addSeller(user: $user) {\n    _id\n    email\n    full_name\n    seller_id\n    company_name\n    token\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4274df6be786a4c7eb1c06c61ec6c4f';

module.exports = node;
