/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserPlaceOrderFormQueryVariables = {|
  slug?: ?string
|};
export type UserPlaceOrderFormQueryResponse = {|
  +getSellerOrderURLBySlug: ?{|
    +product_code: ?string,
    +product_name: ?string,
    +color: ?string,
    +size: ?string,
    +product_description: ?string,
    +product_image: ?$ReadOnlyArray<?string>,
    +weight: ?number,
    +weight_type: ?string,
    +product_price: ?string,
    +delivery_fee: ?number,
    +url_status: ?boolean,
    +seller: ?{|
      +_id: ?string,
      +seller_id: ?string,
      +full_name: ?string,
      +company_name: ?string,
      +phone1: ?string,
      +address_line1: ?string,
      +email: ?string,
    |},
  |}
|};
export type UserPlaceOrderFormQuery = {|
  variables: UserPlaceOrderFormQueryVariables,
  response: UserPlaceOrderFormQueryResponse,
|};
*/


/*
query UserPlaceOrderFormQuery(
  $slug: String
) {
  getSellerOrderURLBySlug(slug: $slug) {
    product_code
    product_name
    color
    size
    product_description
    product_image
    weight
    weight_type
    product_price
    delivery_fee
    url_status
    seller {
      _id
      seller_id
      full_name
      company_name
      phone1
      address_line1
      email
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "OrderUrlReturnType",
    "kind": "LinkedField",
    "name": "getSellerOrderURLBySlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product_price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "delivery_fee",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url_status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderSellerRef",
        "kind": "LinkedField",
        "name": "seller",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "seller_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "full_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "company_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address_line1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPlaceOrderFormQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPlaceOrderFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7c1e3ecb38cd87497537f9978edf602",
    "id": null,
    "metadata": {},
    "name": "UserPlaceOrderFormQuery",
    "operationKind": "query",
    "text": "query UserPlaceOrderFormQuery(\n  $slug: String\n) {\n  getSellerOrderURLBySlug(slug: $slug) {\n    product_code\n    product_name\n    color\n    size\n    product_description\n    product_image\n    weight\n    weight_type\n    product_price\n    delivery_fee\n    url_status\n    seller {\n      _id\n      seller_id\n      full_name\n      company_name\n      phone1\n      address_line1\n      email\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c95d8284dce9c37ed2dccf67ea9ea07';

module.exports = node;
