import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderUrls: [],
};

export const SellerOrderUrlsSlice = createSlice({
  name: "sellerOrderUrls",
  initialState,
  reducers: {
    setOrderUrl: (state, action) => {
      state.orderUrls = action.payload;
    },

    updateOrderUrlList: (state, action) => {
      const updateOrderUrls = action.payload;
      const objIndex = state.orderUrls.findIndex(
        (obj) => obj._id === updateOrderUrls._id
      );
      state.orderUrls[objIndex] = updateOrderUrls;
    },
  },
});

export const { setOrderUrl, updateOrderUrlList } = SellerOrderUrlsSlice.actions;
export default SellerOrderUrlsSlice.reducer;
