import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { setUser } from "../../redux/slices/user";
import { authResponse } from "../../utils/response_messages/auth";
import { authConstants } from "../../utils/authConstants";
import { authUserType } from "../../utils/defaultStatus";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

function SellerSignUp({ setComponent, setAuthComponent }) {
  const dispatch = useDispatch();
  const [registerError, setRegisterError] = useState(null);
  const [commit, isInFlight] = useMutation(graphql`
    mutation SellerSignUpMutation($user: UserInput!) {
      addSeller(user: $user) {
        _id
        email
        full_name
        seller_id
        company_name
        token
      }
    }
  `);

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Create Your Account
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please enter below information to create your account
      </Typography>
      <Formik
        initialValues={{
          fullName: "",
          companyName: "",
          email: "",
          password: "",
          confirmPassword: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().max(50).required("Full name is required"),
          companyName: Yup.string()
            .max(30)
            .required("Business/Display Name is required")
            .matches(/^(\S+$)/, "This field cannot contain blank spaces"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(50)
            .required("Email is required"),
          password: Yup.string()
            .min(8, "Must be at least 8 characters")
            .max(20)
            .required("Password is Required")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
          confirmPassword: Yup.string()
            .when("password", {
              is: (val) => !!(val && val.length > 0),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
              ),
            })
            .required("Confirm password"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const { email, password, fullName, companyName } = values;
          const { SELLER } = authUserType;
          try {
            commit({
              variables: {
                user: {
                  user_name: email,
                  password,
                  user_type: SELLER,
                  user_level: "2nd level user",
                  seller: {
                    full_name: fullName,
                    company_name: companyName,
                    email,
                  },
                  user_status: false,
                  user_email_verify_status: false,
                  user_mobile_verify_status: false,
                  otp_type: "email",
                },
              },
              onCompleted(data) {
                if (data.addSeller) {
                  const newUser = data.addSeller;

                  dispatch(
                    setUser({
                      user: newUser,
                      token: newUser.token,
                      user_type: SELLER,
                      user_id: newUser.seller_id,
                    })
                  );
                  setComponent(authConstants.EMAIL_OTP);
                }
              },
              onError(error) {
                setRegisterError(error.message);
              },
            });
          } catch (error) {
            const message = error.message || authResponse.FAILURE;

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {registerError && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={() => setRegisterError(null)}
              >
                {registerError}
              </Alert>
            )}
            <TextField
              type="text"
              name="fullName"
              label="Full Name"
              value={values.fullName}
              error={Boolean(touched.fullName && errors.fullName)}
              fullWidth
              helperText={touched.fullName && errors.fullName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="companyName"
              label="Business Name/Display Name"
              value={values.companyName}
              error={Boolean(touched.companyName && errors.companyName)}
              fullWidth
              helperText={touched.companyName && errors.companyName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h8">Already have an account?</Typography>
              <Button
                color="primary"
                onClick={() => setAuthComponent(authConstants.SIGN_IN)}
              >
                Sign in
              </Button>
            </div>
            <Button
              mr={2}
              fullWidth
              onClick={() => {
                resetForm();
              }}
              style={{ marginBottom: "10px" }}
            >
              Clear Form
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInFlight}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="12.5"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default SellerSignUp;
