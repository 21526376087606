export const commonConstants = {
  SUCCESS: "success",
  FAILED: "failed",
};
export const loginType = {
  SHIPPER: "shipper",
  SERVICE_PROVIDER: "service_provider",
  EMPLOYEE: "employee",
};
export const paymentTabType = {
  PAYMENT_SUMMARY: "payment_summary",
  PENDING_PAYMENTS: "pending_payments",
  COMPLETED_PAYMENTS: "completed_payments",
  PAYMENT_REPORT: "payment_report",
};
export const invoiceTabType = {
  INVOICE_SUMMARY: "invoice_summary",
  PENDING_INVOICES: "pending_invoices",
  COMPLETED_INVOICES: "completed_invoices",
  INVOICE_REPORT: "invoice_report",
};
export const statusType = {
  FAIL: "fail",
  SUCCESS: "success",
};
export const orderTabType = {
  ORDER_DETAILS: "order_details",
  EDIT_ORDER_DETAILS: "edit_order_details",
};
