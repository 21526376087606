import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import graphql from "babel-plugin-relay/macro";
import { useParams } from "react-router-dom";
import { fetchQuery, useRelayEnvironment, useMutation } from "react-relay";
import { commonConstants } from "../../utils/commonConstants";
// Temporary hidden
// import DistrictSelector from "../../components/auth/components/DistrictSelector";
// import CitySelector from "../../components/auth/components/CitySelectorByDistrict";
import ImageSlider from "./ImageSlider";
import { formatAmount } from "../../utils/commonFunctions";
import ImageNotAvailable from "./GenerateOrderUrl/ImageNotAvailable";
import Loader from "../../components/Loader";

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const initialValues = {
  firstName: "",
  quantity: 1,
  address: "",
  phone1: "",
  phone2: "",
  zipCode: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  quantity: Yup.number()
    .required("Required")
    .positive("Please enter valid quantity")
    .typeError("Please enter valid quantity")
    .integer("Please enter valid quantity"),
  address: Yup.string().required("Required"),
  phone1: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Please enter a valid mobile phone number"),
  phone2: Yup.string()
    .optional("Optional")
    .matches(/^\d{10}$/, "Please enter a valid mobile phone number"),
});

const UserPlaceOrderForm = () => {
  const { token } = useParams();
  const [placeOrderSuccess, setPlaceOrderSuccess] = useState("");
  const [placeOrderError, setPlaceOrderError] = useState("");
  const [state] = useState({
    checkedA: false,
    checkedB: true,
  });
  const [orderTotal, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const environment = useRelayEnvironment();
  const [urlStatus, setUrlStatus] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const [isOrderEmpty, setIsOrderEmpty] = useState(true);

  const fetchUrlDetails = async () => {
    fetchQuery(
      environment,
      graphql`
        query UserPlaceOrderFormQuery($slug: String) {
          getSellerOrderURLBySlug(slug: $slug) {
            product_code
            product_name
            color
            size
            product_description
            product_image
            weight
            weight_type
            product_price
            delivery_fee
            url_status
            seller {
              _id
              seller_id
              full_name
              company_name
              phone1
              address_line1
              email
            }
          }
        }
      `,
      {
        slug: token || "",
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: () => {
        setIsLoading(false);
        setIsOrderEmpty(false);
      },
      next: (data) => {
        setUrlStatus(data.getSellerOrderURLBySlug.url_status);
        setOrderDetails(data.getSellerOrderURLBySlug);
        if (data.getSellerOrderURLBySlug.url_status) {
          setIsOrderEmpty(Object.keys(orderDetails).length === 0);
        }
      },
    });
  };

  useEffect(() => {
    fetchUrlDetails();
  }, []);

  const [commit, isInFlight] = useMutation(graphql`
    mutation UserPlaceOrderFormMutation($customer_order: OrderInputType) {
      addSellerOrder(customer_order: $customer_order) {
        _id
        quantity
        name
        address
        mobile_number1
        mobile_number2
      }
    }
  `);
  const {
    product_code,
    product_name,
    color,
    size,
    product_description,
    weight,
    product_price,
    delivery_fee,
    seller,
    product_image,
  } = orderDetails || {};
  const images = product_image || {};
  const {
    _id,
    seller_id,
    full_name,
    company_name,
    phone1: sellerPhone,
    address_line1,
    email,
  } = seller || {};
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const { firstName, quantity, address, phone1, phone2 } = values;
            commit({
              variables: {
                customer_order: {
                  name: firstName || "",
                  quantity: parseInt(quantity),
                  address,
                  mobile_number1: phone1 || "",
                  mobile_number2: phone2 || "",
                  cod_value: parseFloat(orderTotal),
                  order_url: {
                    product_code: product_code || "",
                    product_name: product_name || "",
                    color: color || "",
                    size: size || "",
                    product_description: product_description || "",
                    weight: weight || "",
                    product_price:
                      (product_price && parseFloat(product_price)) || "",
                    delivery_fee: delivery_fee || "",
                    seller: {
                      _id: _id || "",
                      seller_id: seller_id || "",
                      full_name: full_name || "",
                      company_name: company_name || "",
                      phone1: sellerPhone || "",
                      address_line1: address_line1 || "",
                      email: email || "",
                    },
                  },
                },
              },
              onCompleted(data) {
                if (data.addSellerOrder) {
                  setPlaceOrderSuccess(commonConstants.SUCCESS);
                  resetForm();
                }
              },
              onError(error) {
                setPlaceOrderSuccess(commonConstants.FAILED);
                setPlaceOrderError(error.message);
              },
            });
          } catch (error) {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Card mb={6}>
            {isLoading ? (
              <Loader />
            ) : (
              <CardContent>
                {urlStatus && isOrderEmpty ? (
                  <div>
                    {images?.length > 0 ? (
                      <ImageSlider productImages={images} />
                    ) : (
                      <ImageNotAvailable />
                    )}
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                      >
                        <Grid direction="column" item xs={12} sm={6} md={4}>
                          <Grid item md={4} xs={12} mt={10}>
                            <Typography
                              gutterBottom
                              variant="h4"
                              align="center"
                            >
                              {product_name}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body1"
                                gutterBottom
                              >
                                Price:
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {formatAmount(product_price)}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body1"
                                gutterBottom
                              >
                                Details:
                              </Typography>

                              <Typography
                                variant="body1"
                                gutterBottom
                                align="right"
                              >
                                {product_description}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body1"
                                gutterBottom
                              >
                                Color:
                              </Typography>

                              <Typography variant="body1" gutterBottom>
                                {color}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body1"
                                gutterBottom
                              >
                                Delivery fee:
                              </Typography>

                              <Typography variant="body1" gutterBottom>
                                {formatAmount(delivery_fee)}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body1"
                                gutterBottom
                              >
                                Total:
                              </Typography>

                              <Typography variant="body1" gutterBottom>
                                {formatAmount(
                                  product_price * values.quantity + delivery_fee
                                )}
                                {setTotal(
                                  product_price * values.quantity + delivery_fee
                                )}
                              </Typography>
                            </div>
                          </Grid>

                          <Grid item md={4} xs={12} mt={5} alignItems="center">
                            <TextField
                              name="quantity"
                              label="Quantity"
                              value={values.quantity}
                              error={Boolean(
                                touched.quantity && errors.quantity
                              )}
                              fullWidth
                              helperText={touched.quantity && errors.quantity}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <TextField
                              name="firstName"
                              label="Name"
                              value={values.firstName}
                              error={Boolean(
                                touched.firstName && errors.firstName
                              )}
                              fullWidth
                              helperText={touched.firstName && errors.firstName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <TextField
                              name="address"
                              label="Address"
                              value={values.address}
                              error={Boolean(touched.address && errors.address)}
                              fullWidth
                              helperText={touched.address && errors.address}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <TextField
                              name="phone1"
                              label="Phone Number"
                              value={values.phone1}
                              error={Boolean(touched.phone1 && errors.phone1)}
                              fullWidth
                              helperText={touched.phone1 && errors.phone1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <TextField
                              name="phone2"
                              label="Secondary Phone Number(optional)"
                              value={values.phone2}
                              error={Boolean(touched.phone2 && errors.phone2)}
                              fullWidth
                              helperText={touched.phone2 && errors.phone2}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          </Grid>
                          <Grid item md={4} />
                          {/* Temporary hidden
                          <Grid item md={4} />
                          <Grid item md={4} xs={12}>
                            <DistrictSelector
                              values={values}
                              touched={touched}
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item md={4} /> */}
                          {/* <Grid item md={4} />
                          <Grid item md={4} xs={12} mt={1}>
                            <CitySelector
                              values={values}
                              touched={touched}
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item md={4} /> */}
                          {/* <Grid item md={4} />
                          <Grid item md={4} xs={12}>
                            <TextField
                              name="zipCode"
                              label="Postal code/Zip(optional)"
                              value={values.zipCode}
                              error={Boolean(touched.zipCode && errors.zipCode)}
                              fullWidth
                              helperText={touched.zipCode && errors.zipCode}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          </Grid>
                          <Grid item md={4} /> */}
                          <Grid item md={4} />

                          <Grid item md={1} xs={5}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={state.checkedB}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="Cash"
                            />
                          </Grid>

                          <Grid item md={6} />

                          <Grid item md={4} xs={12}>
                            {placeOrderSuccess === commonConstants.SUCCESS && (
                              <Alert
                                severity="success"
                                my={3}
                                onClose={() => setPlaceOrderSuccess("")}
                              >
                                Your order placed successfully!
                              </Alert>
                            )}
                            {placeOrderSuccess === commonConstants.FAILED && (
                              <Alert
                                severity="error"
                                my={3}
                                onClose={() => setPlaceOrderSuccess("")}
                              >
                                {placeOrderError}
                              </Alert>
                            )}
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              fullWidth
                              disabled={isInFlight}
                            >
                              Order Now
                            </Button>
                          </Grid>
                          <Grid item md={4} />
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                ) : (
                  <Alert severity="warning" my={3}>
                    Sorry this product is not available
                  </Alert>
                )}
              </CardContent>
            )}
          </Card>
        )}
      </Formik>
    </div>
  );
};

export default UserPlaceOrderForm;
