import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
};

export const SellerOrdersSlice = createSlice({
  name: "sellerOrders",
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },

    updateOrderList: (state, action) => {
      const updateOrder = action.payload;
      const objIndex = state.orders.findIndex(
        (obj) => obj._id === updateOrder._id
      );
      state.orders[objIndex] = updateOrder;
    },
  },
});

export const { setOrders, updateOrderList } = SellerOrdersSlice.actions;
export default SellerOrdersSlice.reducer;
