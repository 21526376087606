import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderUrlImages: [],
  originalImages: [],
  imagesForUpload: [],
  // update
  imagesForUploadInUpdate: [],
  editedImages: [],
};

export const SellerOrderUrlImagesSlice = createSlice({
  name: "sellerOrderUrlImages",
  initialState,
  reducers: {
    // Update Images
    setOrderUrlImagesForUpdate: (state, action) => {
      const { payload } = action || [];
      if (payload?.length > 0) {
        state.orderUrlImages = payload;
      }
    },
    setOriginalImagesForUpdate: (state, action) => {
      const { payload } = action || [];
      if (payload?.length > 0) {
        state.originalImages = payload;
      }
    },
    setImagesForUpdateAndUpload: (state, action) => {
      const { payload } = action || [];
      if (payload?.length > 0) {
        state.imagesForUploadInUpdate = payload;
      }
    },
    setEditedImagesForUpdate: (state, action) => {
      const { payload } = action || [];
      const { id } = payload;

      if (state.editedImages?.length > 0) {
        const foundIndex = state.editedImages.findIndex(
          (value) => value.id === id
        );
        if (foundIndex >= 0) {
          state.editedImages[foundIndex] = payload;
        } else {
          state.editedImages = [...state.editedImages, payload];
        }
      } else {
        state.editedImages = [...state.editedImages, payload];
      }
    },
    removeImagesForUpdateAndUpload: (state, action) => {
      const { id } = action.payload;
      const removeEditedIndex = state.editedImages.findIndex(
        (item) => item.id === id
      );
      if (removeEditedIndex < 0) {
        const removeIndex = state.imagesForUploadInUpdate.findIndex(
          (item) => item.id === id
        );
        state.imagesForUploadInUpdate.splice(removeIndex, 1);
      }
    },
    // Insert images
    setOrderUrlImage: (state, action) => {
      state.orderUrlImages = [...state.orderUrlImages, action.payload];
    },
    setOriginalImages: (state, action) => {
      state.originalImages = [...state.originalImages, action.payload];
    },
    setImagesForUpload: (state, action) => {
      state.imagesForUpload = [...state.imagesForUpload, action.payload];
    },
    updateOrderUrlImage: (state, action) => {
      const updateImageUrls = action.payload;
      const objIndex = state.orderUrlImages.findIndex(
        (obj) => obj.id === updateImageUrls.id
      );
      state.orderUrlImages[objIndex] = updateImageUrls;
    },
    updateOrderUrlImageForUpload: (state, action) => {
      const updatedImage = action.payload;
      const objIndex = state.imagesForUpload.findIndex(
        (obj) => obj.id === updatedImage.id
      );
      state.imagesForUpload[objIndex] = updatedImage;
    },
    removeImage: (state, action) => {
      state.orderUrlImages = state.orderUrlImages.filter(
        (image) => image.id !== action.payload
      );
      state.originalImages = state.originalImages.filter(
        (image) => image.id !== action.payload
      );
      state.imagesForUpload = state.imagesForUpload.filter(
        (image) => image.id !== action.payload
      );
      state.imagesForUploadInUpdate = state.imagesForUploadInUpdate.filter(
        (image) => image.id !== action.payload
      );
      state.editedImages = state.editedImages.filter(
        (image) => image.id !== action.payload
      );
    },
    removeAllImages: (state, action) => {
      state.orderUrlImages = [];
      state.originalImages = [];
      state.imagesForUpload = [];
      state.imagesForUploadInUpdate = [];
      state.editedImages = [];
    },
  },
});

export const {
  setOrderUrlImage,
  setOrderUrlImagesForUpdate,
  setOriginalImagesForUpdate,
  setImagesForUpdateAndUpload,
  setEditedImagesForUpdate,
  removeImagesForUpdateAndUpload,
  updateOrderUrlImage,
  removeImage,
  setOriginalImages,
  setImagesForUpload,
  updateOrderUrlImageForUpload,
  removeAllImages,
} = SellerOrderUrlImagesSlice.actions;
export default SellerOrderUrlImagesSlice.reducer;
