import { ShoppingCart } from "react-feather";

const getUserAndSPDisplayName = () => {
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  if (userJson) {
    return {
      shipperDisplayName: userJson?.company_name,
    };
  }
  return "";
};

const pagesSection = [
  {
    href: `/seller/${getUserAndSPDisplayName().shipperDisplayName}/orders`,
    icon: ShoppingCart,
    title: "Orders",
    children: [
      {
        href: `/seller/${
          getUserAndSPDisplayName().shipperDisplayName
        }/orders/PendingOrders`,
        title: "Pending Orders",
      },
      {
        href: `/seller/${
          getUserAndSPDisplayName().shipperDisplayName
        }/orders/generate_url`,
        title: "Generate Order URL",
      },
      {
        href: `/seller/${
          getUserAndSPDisplayName().shipperDisplayName
        }/orders/UrlList`,
        title: "Order URL List",
      },
    ],
  },
];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
