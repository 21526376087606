import * as React from "react";
import useAuth from "../../hooks/useAuth";
import { authUserType } from "../../utils/defaultStatus";

function SellerAuthGuard({ children }) {
  const { isAuthenticated, userType } = useAuth();

  if (isAuthenticated && userType === authUserType.SELLER) {
    return <>{children}</>;
  }

  return "";
}

export default SellerAuthGuard;
