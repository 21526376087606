import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { fetchQuery, useRelayEnvironment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import "./SignUp.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { resetUser } from "../../redux/slices/user";
import useAuth from "../../hooks/useAuth";
import { statusType } from "../../utils/commonConstants";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

function MobileOtp() {
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [otpErrors, setOtpErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newOtpStatus, setNewOtpStatus] = useState("");
  const { sellerSignUp } = useAuth();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const environment = useRelayEnvironment();
  const { email, company_name, seller_mobile_number } = user || {};
  const { FAIL, SUCCESS } = statusType;

  const [commit] = useMutation(graphql`
    mutation MobileOtpMutation($user_name: String, $otp_type: String) {
      resendOtp(user_name: $user_name, otp_type: $otp_type) {
        otp_value
        otp_type
        user {
          user_name
        }
      }
    }
  `);

  const resendMobileOtp = (resetForm) => {
    commit({
      variables: {
        user_name: email,
        otp_type: "mobile",
      },
      onCompleted(data, error) {
        if (data.resendOtp) {
          setMinutes(2);
          setSeconds(0);
          resetForm();
          setOtpErrors("");
          setNewOtpStatus(SUCCESS);
        }
        if (error) {
          setNewOtpStatus(FAIL);
        }
      },
    });
  };

  const fetchOtp = (otp, username, phone1) => {
    fetchQuery(
      environment,
      graphql`
        query MobileOtpQuery(
          $user_name: String
          $otp_value: Int
          $otp_type: String
          $user_mobile: String
        ) {
          verifyUserAccount(
            user_name: $user_name
            otp_value: $otp_value
            otp_type: $otp_type
            user_mobile: $user_mobile
          ) {
            otp_value
            otp_type
          }
        }
      `,
      {
        user_mobile: phone1,
        user_name: username,
        otp_value: otp,
        otp_type: "mobile",
      }
    ).subscribe({
      start: () => {
        setIsLoading(true);
      },
      complete: () => {
        setIsLoading(false);
      },
      error: (e) => {
        setOtpErrors(e.message);
        setIsLoading(false);
      },
      next: (data) => {
        const { verifyUserAccount } = data;
        if (verifyUserAccount) {
          sellerSignUp(user);
          dispatch(resetUser());
          navigate(`/seller/${company_name || ""}/orders/PendingOrders`);
          window.location.reload();
        }
      },
    });
  };

  const otpClick = (mobileOtp) => {
    const otpInt = parseInt(mobileOtp);
    fetchOtp(otpInt, email, seller_mobile_number);
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Verify your mobile phone number
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        We have sent a SMS to your phone number with an OTP. Please enter the
        OTP provided in the SMS below to continue.
      </Typography>
      <Formik
        initialValues={{
          mobileOtp: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          mobileOtp: Yup.string().max(255).required("Mobile OTP is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const { mobileOtp } = values;
          try {
            otpClick(mobileOtp);
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {otpErrors && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={() => setOtpErrors("")}
              >
                {otpErrors}
              </Alert>
            )}
            {newOtpStatus === FAIL && (
              <Alert
                mb={4}
                variant="filled"
                severity="error"
                onClose={() => setNewOtpStatus("")}
              >
                New OTP sending failed. Please try again.
              </Alert>
            )}
            {newOtpStatus === SUCCESS && (
              <Alert
                mb={4}
                variant="filled"
                severity="success"
                onClose={() => setNewOtpStatus("")}
              >
                New OTP sent successfully.
              </Alert>
            )}
            <TextField
              type="text"
              name="mobileOtp"
              label="Enter OTP"
              value={values.mobileOtp}
              error={Boolean(touched.mobileOtp && errors.mobileOtp)}
              fullWidth
              helperText={touched.mobileOtp && errors.mobileOtp}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                mr={2}
                onClick={() => resendMobileOtp(resetForm)}
                style={{ marginBottom: "10px", padding: "0" }}
                disabled={!(minutes === 0 && seconds === 0)}
              >
                Re-send OTP
              </Button>
              {minutes === 0 && seconds === 0 ? (
                <Typography
                  variant="h6"
                  align="end"
                  style={{
                    display: "inline-block",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  0:00 min
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  align="end"
                  style={{
                    display: "inline-block",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds} min
                </Typography>
              )}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || isLoading}
            >
              Next
            </Button>
            <div className="progress-content">
              <LinearProgress
                variant="determinate"
                value="50"
                className="progress"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default MobileOtp;
